/* index.scss */

.hero-banner {
  position: relative;
  overflow: hidden;
  .hero-text {
    position: relative;
    z-index: 2;
    padding: 20px;
    .row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    .col-lg-6,
    .col-md-6 {
      flex: 1 1 50%;
      max-width: 50%;
      padding: 15px;
      text-align: center;
      img {
        max-width: 100%;
        height: auto;
      }
      .title {
        font-size: 2.5rem;
        margin: 20px 0;
      }
      .fs-4 {
        font-size: 1.5rem;
      }
    }
  }
  .hero-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 165px;
    background-size: cover;
    background-position: center;
  }
}

@media (max-width: 992px) {
  .hero-banner .hero-text .col-lg-6,
  .hero-banner .hero-text .col-md-6 {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .hero-banner .hero-text {
    padding: 10px;
    .title {
      font-size: 2rem;
    }
    .fs-4 {
      font-size: 1.2rem;
    }
  }
}

@media (max-width: 576px) {
  .hero-banner .hero-text {
    .title {
      font-size: 1.5rem;
    }
    .fs-4 {
      font-size: 1rem;
    }
  }
}
