.app-header {
  position: absolute;
  z-index: 0;
  width: 100%;
  padding-top: 2rem;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.dark-theme .navbar-sticky {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  box-shadow: none;
  color: #fff;
}
.navbar-purple .navbar-sticky {
  padding-top: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background: #4a62ff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.07);
}
.navbar-sticky {
  z-index: 90;
  top: 0;
  padding-top: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background: #fff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.07);
}
.app-header .navbar {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 500;
  padding: 0.5rem 0;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.buy-token {
  position: relative;
  top: -8px;
  color: #3c434f !important;
  background: #fff;
}
.btn {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  padding: 0.8rem 3rem;
}
.btn-pill {
  border-radius: 50px;
}
.app-header .nav-item .nav-link {
  color: #000;
}
.app-header .nav-item .dropdown-menu {
  width: 14rem;
  margin-top: 0rem;
  border: none;
  background: #3c434f;
}
.app-header .nav-item .dropdown-menu .dropdown-item {
  font-size: 11px;
  padding: 0.5rem 1.5rem;
  color: #fff;
}
.app-header .navbar-collapse {
  margin-top: 0.8rem;
}
.ml-auto {
  margin-left: auto !important;
}
.app-header .nav-item .active {
  color:#25bfbf;
}
@media (min-width: 768px) {
  .app-header .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

@media (max-width: 1024px) {
  .app-header {
    padding-top: 0;
    background: #fff;
  }
}
@media (max-width: 991px) {
  .app-header .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  // .btn {
  //   width: 100px;
  // }
}

@media (max-width: 1024px) {
  .app-header.navbar-purple .navbar-toggler {
    color: #fff !important;
    // background-color: white;
  }
}
.buy-token:hover {
  color: #fff !important;
  background: #25bfbf;
}
.app-header .nav-item .nav-link:hover {
  color:#25bfbf;
}

@media (max-width: 767px) {
  .dark-theme .navbar-toggler-icon {
    color: #fff;
  }
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.logo {
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
  }
}
.title {
  margin-top: 0.1rem;
  font-family: "Black Ops One", cursive;
  color: #071129 !important;
  font-size: 50px !important;
  // @media (max-width: 768px) {
  //   font-size: 20px !important;
  // }
  // @media (max-width: 496px) {
  //   font-size: 12px !important;
  // }
  // @media (max-width: 320px) {
  //   font-size: 7px !important;
  // }
}
