.section-gap-sm {
  padding: 3rem 0;
  background: #071129;
}
@media (max-width: 1024px) {
  .section-gap-sm {
    padding: 1rem 0;
  }
}
@media (max-width: 640px) {
  .partner-brand {
    margin-bottom: 0.5rem;
  }
}
@media (max-width: 767px) {
  .partner-brand {
    margin-bottom: 2rem;
  }
}
@media (max-width: 1024px) {
  .partner-brand {
    margin-bottom: 0rem;
  }
}
