.world-map {
  svg {
    @media (max-width: 1024px) {
      height: auto !important;
    }
  }

  .ant-input-search.ant-input-search-enter-button .ant-input {
    background: transparent;
    border: 2px solid #d3ddef;
    color: #d3ddef;
  }

  .ant-input-search.ant-input-search-enter-button .ant-input:hover {
    border-color: #ffffff;
  }

  .ant-input-search.ant-input-search-enter-button .ant-btn {
    background: #d3ddef;
    border: none;
    color: #071129;
  }

  .ant-input-search.ant-input-search-enter-button .ant-btn:hover {
    background: #ffffff;
  }

  .ant-input-search-button{
    background-color: #fff !important;
    color:#000080 !important
  }
.ant-input-outlined {
background: transparent !important;
color:#fff !important;
  }
  #UA {
    fill: #0f0;
  }
  #UA:hover {
    fill: #008000;
  }
  #ID {
    fill: #F00;
  }
  #ID:hover {
    fill: #800000;
  }
  #IN {
    fill: chocolate;
  }
  #IN:hover {
    fill: rgb(151, 96, 57);
  }
  #GL {
    fill: #b23456;
  }
  #GL:hover {
    fill: #5f031d;
  }
  #BR {
    fill: #00F;
  }
  #BR:hover {
    fill: #000080;
  }
  #CN {
    fill: magenta;
  }
  #CN:hover {
    fill: rgb(75, 0, 75);
  }

  html {
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  html,
  body {
    height: 100%;
  }
  .ag-canvas {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    visibility: visible;
    width: 100%;
    height: 100%;
    padding: 3rem 1rem;
    opacity: 1;
    transition: opacity 0.5s;
  }
  .ag-canvas_svg {
    display: block;
    width: 100%;
    height: auto;
    max-height: 100%;
    margin-left: 1rem;
  }
  .ag-canvas_svg path {
    fill: #383d46;
    fill-opacity: 1;
    stroke: #21252b;
    stroke-opacity: 1;
    stroke-width: 0.5;
    transition: 0.5s;
  }
  @media screen and (max-width: 767px) {
    .ag-canvas {
      -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      height: auto;
      padding: 4rem 1rem 1rem;
    }
    .ag-canvas_svg {
      max-height: calc(100% - 6rem);
      margin-left: 0;
    }
  }
}
