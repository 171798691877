.dark-theme .app-footer {
  background:#071129;
  color: white !important;
}
.app-footer {
  font-family: "Montserrat", sans-serif;
  padding: 3rem 0 0rem 0;
  z-index: 2;
  position: relative;
}

.app-footer h4,
.app-footer h6 {
  margin-bottom: 2rem;
}
.app-footer p {
  font-size: 14px;
}
p {
  margin-bottom: 3rem;
}
.app-footer .copyright {
  font-size: 12px;
}

.dark-theme .app-footer a {
  color: black;
}
.app-footer a {
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  color: #3c434f;
}
.dark-theme .app-footer .secondary-footer {
  border-top: 1px solid #323945;
}
.app-footer .secondary-footer {
  padding-top: 2rem;
  border-top: 1px solid #eaeaf5;
  background: #071129;
  padding-bottom: 2rem;

}
.app-footer .payment-cards img {
  margin-right: 2rem;
}
.sm-txt {
  font-size: 14px;
}
.app-footer .social-links {
  margin-right: 2rem;
}
.dark-theme .app-footer .social-links a {
  color: white;
}
.dark-theme .app-footer a {
  color: white;
}
.app-footer .social-links a {
  font-size: 16px;
}
.app-footer a {
  font-size: 14px;
  color: white;
  margin-bottom: 20px !important;

}
