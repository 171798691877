@import url("https://fonts.googleapis.com/css2?family=Dongle:wght@400;700&family=Outfit:wght@400;600;700&display=swap");

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Outfit", sans-serif;
}

p,
a,
ul li {
  font-family: "Fira Sans", sans-serif;
}
.main-title {
  width: 100%;
  text-align: center;
  color: #ffffff;
}
.slider1 {
  margin: 80px 0;
  height: 200px;
  display: block;
  perspective: 1000px;
  position: relative;
  width: 100vw;
}

.slide {
  margin-left: -50px;
  height: 250px;
  display: inline-block;
  width: 400px;
  position: absolute;
  backface-visibility: hidden;
  transition: 0.4s all;
  border-radius: 20px;
  color: white;
  backdrop-filter: blur(20px);
  border: double 1px transparent;
  border-radius: 30px;
  background-image: linear-gradient(#0200546b, #0000006b);
  background-origin: border-box;
  background-clip: content-box, border-box;

  @media (max-width: 768px) {
    width: 80%;
    height: 280px;
    margin-left: 0;
    position: relative;
    display: none; // Hide all slides by default
  }

  &.active {
    z-index: 4;
    @media (max-width: 768px) {
      display: inline-block; // Only show the active slide
    }
  }
}

.title {
  z-index: 3;
  position: relative;
}

.slide-container {
  padding: 20px 30px;
  @media (max-width: 768px) {
    padding: 20px 50px;
  }
}

.slide-container ul {
  margin: 0;
  padding: 0;
}
.slide-container ul li {
  padding: 0;
}
