/* index.scss */

.section-gap {
    padding: 5rem 0;
    background: #071129;
  }
  .transparency{
    background: #071129 !important;

  }
  
  @media (max-width: 1024px) {
    .section-gap {
      padding: 3rem 0;
    }
  }
  
  @media (max-width: 767px) {
    .col-gap {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  
  .col-gap {
    padding-right: 30px;
    padding-left: 30px;
  }
  
  .dark-theme .card {
    background: #ffffff;
  }
  
  .box-show {
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.08);
  }
  
  .card {
    border: none;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.05);
  }
  
  .card {
    border-radius: 0.5rem;
  }
  
  .newCard {
    background: #ffffff !important;
    color: #071129 !important;
  }
  
  .text-paste-color1 {
    color: #071129 !important;
  }
  
  @media (max-width: 767px) {
    .section-title h2,
    .section-title h3,
    .h3,
    h3 {
      font-size: 1.5rem;
    }
  }
  
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
  }
  
  @media (max-width: 1024px) {
    .section-title,
    p {
      margin-bottom: 2rem;
    }
  }
  
  p {
    margin-bottom: 3rem;
  }
  
  @media (max-width: 1024px) {
    .h2,
    h2 {
      font-size: 1.5rem;
    }
  }
  
  .h2,
  h2 {
    font-size: 2.3rem;
  }
  
  .text-purple-color {
    color: #071129;
  }
  
  .border {
    border-width: 1px !important;
    border-style: solid !important;
    margin-bottom: 1rem;
  }
  
  @media (max-width: 767px) {
    .border {
      margin-bottom: 1rem;
    }
  }
  
  .mb-3,
  .mb-md-0 {
    margin-bottom: 1rem !important;
  }
  
  .p-4 {
    padding: 1.5rem !important;
  }
  @media (max-width:992px) {
    .newRow{
        .newCol{
            width: 100%;
        }
    }
  }
  
  .rounded {
    border-radius: 0.5rem !important;
  }
  
  .g-2 {
    gap: 2rem !important;
  }
  
  .flex-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
  
  .flex-item {
    flex: 1;
    min-width: calc(50% - 1rem); /* Adjust for gap */
  }
  
  @media (max-width: 1024px) {
    .flex-item {
      min-width: 100%;
    }
  }
  .graph{
      // backdrop-filter: blur(1.15625rem);
      background: rgba(32, 31, 43, 0.849);
      border-radius: .625rem;
      border-top: .0625rem solid hsla(0, 0%, 100%, .25);
      height: 30.0625rem;
      padding: 1.875rem 1.25rem 3.75rem;
      position: relative;
      width: 33.75rem;
  
  }
.graph::after{
  background: linear-gradient(165deg, #18a594 17.34%, #1b2b64 97.64%);
    border-radius: .625rem;
    content: "";
    display: block;
    height: 28.75rem;
    opacity: .5;
    position: absolute;
    right: -2.5rem;
    top: -2.5rem;
    width: 28.75rem;
    z-index: -1;
}
@media (max-width: 992px) {
  .graph{
    margin-top: 100px !important;
    height: 17.625rem;
    padding: 2.25rem 1.25rem;
width: 100%;
  }
 .graph:after {
      height: 16.875rem;
      right: .125rem;
      top: -1.4675rem;
 width: 100%;
  }
}