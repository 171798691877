.mainWidgetContainer {
  margin-top: 100px;
}

.bg-main {
  background-color: #071129;
}

.bg-transparent {
  background-color: transparent;
}

.card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.card-container .ant-card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.mainWidgetContainer {
  .ant-select {
    width: 25%;
    position: absolute;
    top:6px; 
    right: 5px;
    height: 36px !important;
    border-radius: 6px !important;

    // padding: 15px;
    // font-size: 20px;
    .ant-select-selector {

      background: #2c354bce !important;


      border: none !important;
      // border: 1px solid white !important;
      border-radius: 6px !important;

      // padding: 15px;
      // font-size: 20px;
    }
    .ant-select-selection-item {
      color: white !important;
    }
    .ant-select-arrow {
      color: white !important;
    }
  }

  .ant-input {
    background-color: transparent !important;
    border: none !important;
    border: 1px solid white !important;
    border-radius: 6px !important;
    padding: 10px;
    font-size: 17px;
    color: white !important;
    &::placeholder {
      color: rgba(255, 255, 255, 0.562) !important;
      font-weight: 600 !important;
      font-size: 17px;
    }
  }
  .labels {
    color: white;
  }
}

.input-group {
  display: flex;
  align-items: center;
  .flex-grow-1 {
    flex-grow: 1;
  }
  .currency-select {
    flex-basis: 33.33%;
    margin-left: 8px;
  }
}

@media (min-width: 992px) {
  .mainWidgetContainer {
    background-image: url("../../../assets/cross-acde3e05f8219e4867b334bbae411247.svg"), url("../../../assets/dots-af861a575a3de6dab3b76f5ff50ce51d.svg"), url("../../../assets/cross-line-67fc1265bc13134be26132dd81e28f32.svg"), url("../../../assets/big-cross-f0d94b7df4d1c7661fd7c1d7071397b7.svg");
    padding: 30px 0 !important;
    background-repeat: no-repeat;
    background-position: 207px 90px, 0 0, 219px 624px, 100% 165px;
    margin-top: 100px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.swap-button{
  background: #071129;
  color: white;
  border-color: white;
  border-radius: 40px;
  padding: 6px 35px;
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
  &:hover{
  background: white;
  border-color: white;
  color: #071129;

  }

}
.ant-statistic-title{
  color: white !important;
  font-size: 1rem !important;

}
.ant-statistic-content{
  color: white !important;
  font-weight: 800 !important;
  font-size: 2.3rem !important;
  
}
.ant-select-dropdown{
  background: #2c354bce !important;
color: white !important;
.ant-select-item{
  color: white !important;
}
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: #071129 !important;
  color: white !important;
}