body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #2a303b; */
  overflow-x: hidden;
}
html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
body {
  height: 100%;
}
html {
  height: 100%;
}
#root {
  height: 100%;
}
.dark-theme {
  color: #fff;
  /* background: #323945; */
}
img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  border: 0 none;
  outline: 0;
}
a,
a:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-decoration: none;
  outline: 0;
}
/* @media (max-width: 1024px) {
  .dark-theme .app-header.navbar-purple {
    background: #000;
  }
} */

.ant-modal-content{
  background-color: #071129 !important;
}
.ant-modal-title{
  background-color: #071129 !important;
  color: white !important;


}
.ant-modal-close-x{
  color: white !important;
}


.ant-select {
  width: 25%;
  position: absolute;
  right: 5px;
  height: 36px !important;
  border-radius: 6px !important;

  // padding: 15px;
  // font-size: 20px;
  .ant-select-selector {

    background: #2c354bce !important;


    border: none !important;
    // border: 1px solid white !important;
    border-radius: 6px !important;

    // padding: 15px;
    // font-size: 20px;
  }
  .ant-select-selection-item {
    color: white !important;
  }
  .ant-select-arrow {
    color: white !important;
  }
}

.ant-input {
  background-color: transparent !important;
  border: none !important;
  border: 1px solid white !important;
  border-radius: 6px !important;
  padding: 10px;
  font-size: 17px;
  color: white !important;
  &::placeholder {
    color: rgba(255, 255, 255, 0.562) !important;
    font-weight: 600 !important;
    font-size: 17px;
  }
}
.labels {
  color: white;
}


.input-group {
display: flex;
align-items: center;
.flex-grow-1 {
  flex-grow: 1;
}
.currency-select {
  flex-basis: 33.33%;
  margin-left: 8px;
}
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Firefox */
input[type=number] {
-moz-appearance: textfield;
}


.ant-select-dropdown{
background: #2c354bce !important;
color: white !important;
.ant-select-item{
color: white !important;
}
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
background-color: #071129 !important;
color: white !important;
}