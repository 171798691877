@media (max-width: 1024px) {
  .section-gap-sm {
    padding: 1rem 0;
  }
}
.feature-gradient {
  color: #071129;
  background: white !important;

  // background-color: black;
  // background-image: -webkit-linear-gradient(left, #0077b6 0%, #0496ff 100%);
  // background-image: linear-gradient(to right, #0077b6 0%, #0496ff 100%);
}
.section-gap-sm {
  padding: 3rem 0;

}
.bg-abc{
  background: white !important;

}
// #contact-box {
//   margin-top: -320px;
// }
.dark-theme .text-dark {
  color: #071129 !important;
}
.dark-theme .card {
  // background: white;
}
.card {
  border: none;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.05);
}
.card {
  border-radius: 0.5rem;
}
@media (max-width: 640px) {
  .section-title h2,
  .section-title h3,
  .h3,
  h3,
  .h2,
  h2 {
    font-size: 1.1rem;
  }
}

@media (max-width: 767px) {
  .section-title h2,
  .section-title h3,
  .h3,
  h3 {
    font-size: 1.5rem;
  }
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

@media (max-width: 1024px) {
  .section-title,
  p {
    margin-bottom: 2rem;
  }
}
p {
  margin-bottom: 3rem;
}
.contact-form .form-control,
.login-form .form-control {
  line-height: 2.2;
  border-color: #000;
}
.help-block.with-errors {
  color: #fe413b;
}
.help-block {
  font-size: 14px;
}
.subscribe-from .form-control {
  line-height: 2;
  padding: 0.5rem 1.5rem;
  color: #000;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 40px;
  background: transparent;
}
.contact-form .btn.disabled,
.contact-form .btn:disabled,
.login-form .btn.disabled,
.login-form .btn:disabled {
  opacity: 1;
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}
@media (max-width: 640px) {
  .btn {
    font-size: 11px;
    padding: 0.5rem 2rem;
  }
}
.submit-button {
  color: #fff !important;
  padding: 10px 25px !important;
  background:#071129 !important;
  border-radius:40px !important;
}
.btn-pill {
  border-radius: 50px;
}
.btn {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  padding: 0.8rem 3rem;
}
.subscribe-from button {
  line-height: 2;
  border-radius: 40px;
}
